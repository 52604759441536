<template>
  <fg-input v-model="configData[nameFiled]"
            :data-cy="nameFiled"
            :name="nameFiled"
            :label="nameFiled">
    <el-select class="select-default" v-model="configData[nameFiled]">
      <el-option class="select-default" value="0">0</el-option>
      <el-option class="select-default" :value="val" v-for="val in 12" :key="val">{{val}}</el-option>
    </el-select>
  </fg-input>
</template>
<script>
  import {Select, Option} from 'element-ui'
  export default {
    name: 'SelectGrid',
    components: {
      [Select.name]: Select,
      [Option.name]: Option
    },
    props: {
      nameFiled: {type: String, required: true},
      configData: {type: Object, required: true}
    }
  }
</script>
