<template>
  <div>
    <h5 class="text-center">Укажите нужную информацию</h5>
    <div class="row">
      <div class="col-md-4">
        <fg-input label="Api"
                  name="api"
                  v-validate="modelValidations.api"
                  v-model="model.api"
                  :error="getError('api')"
                  placeholder="ex: user">
        </fg-input>
      </div>
      <div class="col-md-4">
        <fg-input label="Entity Name"
                  name="entityName"
                  v-validate="modelValidations.entityName"
                  v-model="model.entityName"
                  :error="getError('entityName')"
                  placeholder="ex: user">
        </fg-input>
      </div>
      <div class="col-md-4">
        <fg-input label="Api"
                  name="api"
                  v-validate="modelValidations.path"
                  v-model="model.path"
                  :error="getError('path')"
                  placeholder="ex: user">
        </fg-input>
      </div>

      <div class="col-md-4">
        <fg-input label="title"
                  name="title"
                  v-validate="modelValidations.title"
                  v-model="model.title"
                  :error="getError('title')"
                  placeholder="ex: title">
        </fg-input>
      </div>

      <div class="col-md-4">
        <fg-input label="Title Edit"
                  name="titleEdit"
                  v-validate="modelValidations.titleEdit"
                  v-model="model.titleEdit"
                  :error="getError('titleEdit')"
                  placeholder="ex: title">
        </fg-input>
      </div>


    </div>

  </div>
</template>
<script>
  export default {
    data() {
      return {
        model: {
          api: 'user',     // api обычно == сущности
          entityName: 'USER', // название сушности
          title: 'User',
          titleEdit: 'User Edit',
          path: 'user',
          role: [],    // роли для роутера
          parent: []  // возможно уберем отсюда
        },
        modelValidations: {
          api: {
            required: true,
            min: 1
          },
          entityName: {
            required: true,
            min: 1
          },
          title: {
            required: true,
            min: 1
          },
          titleEdit: {
            required: true,
          },
          path: {
            required: true,
          }
        }
      }
    },
    methods: {
      getError(fieldName) {
        return this.errors.first(fieldName)
      },
      validate() {
        return this.$validator.validateAll().then(res => {
          this.$emit('on-validated', res, this.model)
          return res
        })
      }
    }
  }
</script>
<style>
</style>
