<template>
  <div>
    <div class="row d-flex justify-content-center">
      <div class="col-sm-12">
        <div class="card card-wizard" id="wizardCard">
          <form-wizard shape="tab"
                       subtitle="Следуйте указаниям генератора"
                       title="Генератор форм v1 "
                       @on-complete="wizardComplete"
                       error-color="#FB404B"
                       color="#35495E">
            <tab-content title="Шаг первый"
                         class="col-12"
                         :before-change="() => validateStep('firstStep')"
                         icon="nc-icon nc-badge">
              <first-step ref="firstStep" @on-validated="onStepValidated"></first-step>
            </tab-content>

            <tab-content title="Шаг второй"
                         class="col-12"
                         :before-change="() => validateStep('secondStep')"
                         icon="nc-icon nc-notes">
              <second-step ref="secondStep" @on-validated="onStepValidated"></second-step>
            </tab-content>

            <tab-content title="Последний шаг"
                         class="col-12"
                         icon="nc-icon nc-check-2">
              <div>
                <h2 class="text-center text-space">Успех
                  <br>
                  <small>Кликни на "<b>Закончить</b>" что бы скачать схему.</small>
                </h2>
                <p>Помести файл в папку generatorCLI и запусти скрипт</p>
                <pre>{{wizardModel}}</pre>
              </div>
            </tab-content>
            <button slot="prev" class="btn btn-default btn-fill btn-wd btn-back">Назад</button>
            <button slot="next" class="btn btn-default btn-fill btn-wd btn-next">Дальше</button>
            <button slot="finish" class="btn btn-success btn-fill btn-wd">Закончить</button>
          </form-wizard>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {FormWizard, TabContent} from 'vue-form-wizard'
  import 'vue-form-wizard/dist/vue-form-wizard.min.css'
  import FirstStep from './Wizard/FirstStep.vue'
  import SecondStep from './Wizard/SecondStep.vue'
  import swal from 'sweetalert2'

  export default {
    data() {
      return {
        wizardModel: {},
      }
    },
    components: {
      FormWizard,
      TabContent,
      FirstStep,
      SecondStep
    },
    methods: {
      validateStep(ref) {
        return this.$refs[ref].validate()
      },
      onStepValidated(validated, model) {
        this.wizardModel = {...this.wizardModel, ...model}
      },
      wizardComplete() {
        swal({
          title: 'Удачи?',
          text: "Всего хорошего держитесь здес",
          type: 'success',
          confirmButtonText: 'Скачать',
        }).then((result) => {
          let text = JSON.stringify(this.wizardModel);
          let a = document.createElement("a");
          let file = new Blob([text], {type: 'application/json'});
          a.href = URL.createObjectURL(file);
          a.download = "scheme.json";
          a.click();
        } )

      }
    }
  }
</script>
<style lang="scss">
  .vue-form-wizard .wizard-icon-circle.tab_shape {
    background-color: #9A9A9A !important;
    color: white;
  }

  .vue-form-wizard .wizard-tab-content {
    display: flex; // to avoid horizontal scroll when animating
    .wizard-tab-container {
      display: block;
      animation: fadeIn 0.5s;
    }
  }
</style>
