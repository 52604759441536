<template>
  <div :class="classIn" v-if="nameFiled in configData">
    <p class="category">{{label}}</p>
    <l-switch v-model="configData[nameFiled]" type="primary">
      <i class="fa fa-check" slot="on"></i>
      <i class="table_icon icon_delete" slot="off"></i>
    </l-switch>
  </div>
</template>
<script>
  import {Switch as LSwitch} from 'src/components/index'

  export default {
    name: 'SwitchConfig',
    components: {
      LSwitch,
    },
    props: {
      classIn: {type: String, default: 'col-3'},
      nameFiled: {type: String, required: true},
      label: {type: String, required: true},
      configData: {type: Object, required: true},
      disabled: {type: Boolean, default: false}
    }
  }
</script>
