<template>
    <fg-input :label="nameFiled" :disabled="disabled" :name="nameFiled" :data-cy="nameFiled" v-model="configData[nameFiled]"  v-if="nameFiled in configData"></fg-input>
</template>
<script>
    export default {
        name: 'InputGenerator',
        props: {
          nameFiled: {type: String, required: true},
          configData: {type: Object, required: true},
          disabled: {type: Boolean, default: false }
        }
    }
</script>
