<template>
  <div>
    <h5 class="text-center">Сгенерируйте форму</h5>
    <div class="row">
      <div class="col-2">
        <card>
          <div slot="header">
            <h4 class="title">Компоненты</h4>
          </div>
          <div>
            <l-button
              :disabled="!comp.enable"
              block
              v-for="(comp, index) in componentsList"
              :key="index"
              @click="addComp(comp)">{{comp.type}}
              <i class="el-icon-plus el-icon-right"></i>
            </l-button>
          </div>
        </card>
      </div>

      <div :class="{'col-4' : (showResult==true) , 'col-10' : (showResult==false)  }">

        <card>
          <div slot="header">
            <h4 class="title">Конфигурация</h4>
          </div>
          <div>
            <config-form :configData="selectComponent"

                         :cursorId=cursorId
                         @delElement="delElement"
                         @moveElement="moveElement"
                         @setCursor="setCursor"
                         :ind="index"
                         :count="selectComponents.length"

                         v-for="(selectComponent, index) in selectComponents" :key="index"></config-form>

            <l-button type="warning" block wide outline v-if="cursorId !== null"
                      @click="setCursor({id: null})">Добавить сюда
            </l-button>
            <l-button type="primary" block wide outline
                      @click="showResult=!showResult">{{ showResult ? 'Спрятать' : 'Показать' }} результат
            </l-button>
          </div>
        </card>
      </div>
      <div class="col-6">
        <card v-if="showResult">
          <div slot="header">
            <h4 class="title">Результат</h4>
          </div>
          <card>
            <show-code :code="form" ></show-code>
            <show-code :code="selectComponents" title="showSelectComponents"></show-code>
            <dynamic-generator-form :form="form" :configData="selectComponents"></dynamic-generator-form>
          </card>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
  import {mapFields} from 'vee-validate'
  import COMPONENTS_LIST from '@/helpers/generatorForm/componentsList.js'
  import ConfigForm from "../../../../components/modules/generator/ConfigForm.vue";

  import clearReactive from '@/helpers/clearReactive.js'
  import convertJsonShemeToData from "@/helpers/generatorForm/convertJsonShemeToData.js"
  import ShowCode from "../../../../components/shared/ShowCode.vue";

  export default {
    components: {
      ShowCode,
      ConfigForm
    },
    data() {
      return {
        componentsList: COMPONENTS_LIST,
        selectComponents: [],
        form: {},
        cursorId: null,
        showResult: true
      }
    },
    watch: {
      selectComponents: {
        deep: true,
        handler() {
          this.generateForm()
        }
      }
    },
    methods: {
      addComp(data) {
        let id = Math.round(Math.random() * (1000))
        let convertData = {...clearReactive(data), id: id}
        if (this.cursorId) {
          this.selectComponents = this.selectComponents.map(el => {
            if (this.cursorId == el.id) {
              el.children.push(convertData)
              return el
            } else {
              return el
            }
          })

        } else {
          this.selectComponents.push(clearReactive(convertData))
        }
      },

      setCursor(el) {
        this.cursorId = el.id
      },

      delElement(el) {
        this.selectComponents = this.selectComponents.filter(c => c.id !== el.id)
      },
      moveElement(el, up = true) {
        let selectComponents = clearReactive(this.selectComponents)
        let findIndex = selectComponents.findIndex(c => c.id == el.id)

        let temp1 = selectComponents[findIndex - 1]
        let temp2 = selectComponents[findIndex]

        if (!up) {
          temp1 = selectComponents[findIndex + 1]
          selectComponents[findIndex + 1] = temp2
        } else {
          selectComponents[findIndex - 1] = temp2
        }
        selectComponents[findIndex] = temp1
        this.selectComponents = selectComponents
      },

      generateForm() {
        this.form = convertJsonShemeToData(this.selectComponents)
      },
      getError(fieldName) {
        return this.errors.first(fieldName)
      },
      validate() {
        return this.$validator.validateAll().then(res => {
          this.$emit('on-validated', res, {configForm: this.selectComponents})
          return res
        })
      }
    }
  }
</script>
<style>
</style>
