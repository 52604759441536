<template>
  <card>
    <el-collapse>
      <p class="card-category">{{configData.description}}</p>
      <el-collapse-item :title="configData.type" name="1">
        <div class="row">
          <div class="col-6">
            <input-generator nameFiled="label" :configData="configData"></input-generator>
            <input-generator nameFiled="fieldName" :configData="configData"></input-generator>
            <input-generator nameFiled="relative" :configData="configData"></input-generator>
            <input-generator nameFiled="selectLabel" :configData="configData"></input-generator>
            <input-generator nameFiled="placeholder" :configData="configData"></input-generator>
            <input-generator nameFiled="name" :configData="configData"></input-generator>
            <input-generator nameFiled="default" :configData="configData"></input-generator>
            <input-generator nameFiled="addClass" :configData="configData"></input-generator>
          </div>
          <div class="col-6">
            <select-grid nameFiled="colsLG" :configData="configData"></select-grid>
            <select-grid nameFiled="colsMD" :configData="configData"></select-grid>
            <select-grid nameFiled="colsSM" :configData="configData"></select-grid>
            <select-grid nameFiled="colsXS" :configData="configData"></select-grid>

            <select-grid nameFiled="OffsetLG" :configData="configData"></select-grid>
            <select-grid nameFiled="OffsetMD" :configData="configData"></select-grid>
            <select-grid nameFiled="OffsetSM" :configData="configData"></select-grid>
            <select-grid nameFiled="OffsetXS" :configData="configData"></select-grid>

          </div>
          <div class="col-6">
            <input-generator nameFiled="cyData" :configData="configData"></input-generator>
            <input-generator nameFiled="errName" :configData="configData" :disabled="true"></input-generator>
            <input-generator nameFiled="validationRule" :configData="configData" :disabled="true"></input-generator>

            <div class="row">
              <switch-config nameFiled="main" label="Main" :configData="configData"></switch-config>
              <switch-config nameFiled="search" label="Search" :configData="configData"></switch-config>
              <switch-config nameFiled="readonly" classIn="col-4" label="Readonly"
                             :configData="configData"></switch-config>
              <switch-config nameFiled="showTable" classIn="col-6" label="Show in Table"
                             :configData="configData"></switch-config>
              <switch-config nameFiled="disableInCreate" classIn="col-6" label="Disable In Create"
                             :configData="configData"></switch-config>

            </div>
          </div>

          <div class="col-6">
            <div v-if="'variant' in configData">
              <varian-generator :configData="configData"></varian-generator>
            </div>

          </div>
        </div>
      </el-collapse-item>
    </el-collapse>

    <div v-if="configData.children">
      <config-form
        :cursorId = cursorId
        @delElement="delElement"
        @moveElement="moveElement"
        :noCursor="true"
        :ind="index"
        :count="configData.children.length"
        :configData="child"
        v-for="(child, index) in configData.children"
        :key="child.id">
      </config-form>
    </div>

    <div class="row">
      <div class="col-6">
        <l-button type="info" block wide outline @click="$emit('moveElement', configData, true)" v-if="ind !== 0">
          <span class="btn-label"><i class="nc-icon nc-stre-up"></i></span>Вверх
        </l-button>
      </div>
      <div class="col-6">
        <l-button type="info" block wide outline @click="$emit('moveElement', configData, false)"
                  v-if="ind !== (count - 1)">
          <span class="btn-label"><i class="nc-icon nc-stre-down"></i></span>Вниз
        </l-button>
      </div>
    </div>
    <l-button type="danger" block wide outline @click="$emit('delElement', configData)">
                <span class="btn-label"><i class="table_icon icon_delete"></i>
                </span>Удалить
    </l-button>

    <l-button type="warning" v-if="( ('children' in configData) && cursorId !== configData.id)" block wide outline
              @click="$emit('setCursor', configData)">Добавить сюда
    </l-button>

  </card>
</template>
<script>
  import {Select, Option} from 'element-ui'
  import SelectGrid from "./SelectGrid.vue";
  import {Collapse, CollapseItem} from 'element-ui'
  import InputGenerator from "./InputGenerator.vue";
  import Card from "../../shared/Cards/Card.vue";
  import VarianGenerator from "./VarianGenerator.vue";
  import SwitchConfig from "./SwitchConfig.vue";
  import clearReactive from '@/helpers/clearReactive.js'

  export default {
    name: 'ConfigForm',
    components: {
      SwitchConfig,
      VarianGenerator,
      Card,
      InputGenerator,
      SelectGrid,
      [Select.name]: Select,
      [Option.name]: Option,
      [Collapse.name]: Collapse,
      [CollapseItem.name]: CollapseItem,
    },
    props: {
      cursorId: {},
      configData: {type: Object, required: true},
      count: {},
      ind: {},
      hasCursor: {type: Boolean, default: true }
    },
    methods:{
      delElement(el) {
        this.configData.children = this.configData.children.filter(c => c.id !== el.id)
      },
      moveElement(el, up = true) {
        let selectComponents = clearReactive(this.configData.children)
        let findIndex = selectComponents.findIndex(c => c.id == el.id)

        let temp1 = selectComponents[findIndex - 1]
        let temp2 = selectComponents[findIndex]

        if(!up){
          temp1 = selectComponents[findIndex + 1]
          selectComponents[findIndex + 1] = temp2
        }  else {
          selectComponents[findIndex - 1] = temp2
        }
        selectComponents[findIndex] = temp1
        this.configData.children = selectComponents
      },
    }
  }
</script>
