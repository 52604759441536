<template>
  <card>
    <p>Введите варианты</p>
      <ol>
        <li v-for=" variant in configData.variant "> {{variant.value}} => {{variant.label}}</li>
      </ol>
    <fg-input label="value" v-model="valueKey"></fg-input>
    <fg-input label="label" v-model="label"></fg-input>
    <l-button type="info" block wide outline @click="addVariant">Добавить</l-button>
  </card>
</template>
<script>
  export default {
    name: 'VarianGenerator',
    props: {
      configData: {
        type: Object, required: true
      }
    },

    data() {
      return {
        valueKey: '',
        label: ''
      }
    },
    methods: {
      addVariant() {
        this.configData.variant.push({value: this.valueKey, label: this.label})
      }
    }
  }
</script>
